<template>
  <div class="cinema_testing_result">
<!--    <NavBar title="影厅报告" class="nav_bottom"></NavBar>-->
    <main v-if="main_flag">
      <!--声道begin-->
      <div class="track">
        <div class="tip">
          <p>声道</p>
          <span>(支持6声道: 左、右、中、低频、左环、右环）</span>
        </div>
        <div class="all_btn success" v-if="track_flag">支持</div>
        <div class="all_btn error" v-else>缺失</div>
        <span class="status" v-if="!track_flag">!</span>
      </div>
      <!--声道end-->
      <!--响度begin-->
      <div class="loudness">
        <div class="title">
          <p>响度</p>
          <span>(主声道85,环绕声道82,低频声道91允差±3dBC)</span>
        </div>
        <div class="main">
          <div class="item" v-for="v in loudness_arr">
            <p>{{ v.name }}</p>
            <div class="state">{{v.state}}</div>
            <div class="all_input">{{v.num}}</div>
            <span class="status" v-if="!loudness_err(v)">!</span>
          </div>
        </div>
      </div>
      <!--响度end-->
      <!--放映过程中交流声begin-->
      <div class="ac_sound">
        <div class="tip">
          放映过程中干扰声
        </div>
        <div class="all_btn success" v-if="ac_sound_flag">无</div>
        <div class="all_btn error" v-else>有</div>
        <span class="status" v-if="!ac_sound_flag">!</span>
      </div>
      <!--放映过程中交流声end-->
      <!--银幕中心亮度2D 11-17F1begin-->
      <div class="brightness_2d">
        <div class="tip">
          银幕中心亮度2D<br>11-17F1
        </div>
        <div class="all_input">{{brightness_2d_text}}</div>
        <span class="status" v-if="brightness_2d_err">!</span>
      </div>
      <!--银幕中心亮度2D 11-17F1end-->
      <!--银幕中心亮度3D大于 3.975F1begin-->
      <div class="brightness_3d">
        <div class="tip">
          银幕中心亮度3D<br>大于 3.975F1
        </div>
        <div class="all_input" v-if="brightness_3d_flag">{{brightness_3d_text}}</div>
        <div class="all_btn error" v-else>无</div>
        <span class="status" v-if="brightness_3d_err">!</span>
      </div>
      <!--银幕中心亮度3D大于 3.975F1end-->
      <!--清晰度begin-->
      <div class="definition">
        <div class="tip">
          清晰度
        </div>
        <div class="all_btn success" v-if="definition_flag">正常</div>
        <div class="all_btn error" v-else>模糊</div>
        <span class="status" v-if="!definition_flag">!</span>
      </div>
      <!--清晰度end-->
      <!--汇聚begin-->
      <div class="convergence">
        <div class="tip">
          <p>汇聚</p>
          <span>像素偏差0.5个像素（含）</span>
        </div>
        <div class="all_btn success" v-if="convergence_flag">正常</div>
        <div class="all_btn error" v-else>偏差</div>
        <span class="status" v-if="!convergence_flag">!</span>
      </div>
      <!--汇聚end-->
      <!--座位数begin-->
<!--      <div class="seats_num">
        <div class="tip">
          座位数
        </div>
        <div class="all_input">{{seats}}</div>
      </div>-->
      <!--座位数end-->
      <!--设备信息begin-->
      <div class="device_info">
        <p>设备信息</p>
        <div class="device_projector">
          <div class="tip">
            放映机
          </div>
          <div class="all_device_input">
            {{ projector }}
          </div>
        </div>
        <div class="device_server">
          <div class="tip">
            服务器
          </div>
          <div class="all_device_input">
            {{ server }}
          </div>
        </div>
        <div class="device_3d">
          <div class="tip">
            3D设备
          </div>
          <div class="all_device_input">
            {{ equipment }}
          </div>
        </div>
        <div class="device_decoder">
          <div class="tip">
            解码器
          </div>
          <div class="all_device_input">
            {{ decoder }}
          </div>
        </div>
      </div>
      <!--设备信息end-->
      <!--  备注begin  -->
      <div class="beizhu" v-if="other">
        <p>备注</p>
        <div class="beizhu_content">{{other}}</div>
      </div>
      <!--  备注end  -->
    </main>
    <el-empty description="暂时没有报告" v-if="empty_flag"></el-empty>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import {GetOneHall, getOneHallResult} from '../axios/api'
import {Empty,Loading} from "element-ui";
import {Toast} from "vant"
export default {
  name: "CinemaTestingResult",
  created() {
    let loadingInstance = Loading.service({ fullscreen: true , text: "加载中" , background:"#F6F7F8"});

    //这里有问题
    if(!this.$route.params.id){
      console.log('不存在id')
      loadingInstance.close();
      Toast.fail("获取失败，请重新进入")
      //用户刷新 或者查询不到路由参数的id
      if(this.$route.query.see){
        this.$router.push('/detectionHistory')
      }else{
        this.$router.push("/cinemaAdd")
      }
      return;
    }

    //检测人员查看
    if(this.$route.query.see=='y'){
      GetOneHall({
        hallid:this.$route.params.id,
        token:localStorage.getItem("token")
      }).then(respose=>{
        if(respose.state==="1"){
          this.main_flag=true;
          /*初始化数据*/
          this.track_flag=respose.data.channel==="支持"
          this.ac_sound_flag=respose.data.hum==="无"
          this.brightness_2d_text=respose.data.bright2D
          this.brightness_3d_flag=respose.data.bright3D!=="无"?this.brightness_3d_text=respose.data.bright3D:false
          this.definition_flag=respose.data.clarity==="正常"
          this.convergence_flag=respose.data.converge==="正常"
          this.loudness_arr[0].num=respose.data.middle
          this.loudness_arr[0].state=respose.data.exmiddle
          this.loudness_arr[1].num=respose.data.left
          this.loudness_arr[1].state=respose.data.exleft
          this.loudness_arr[2].num=respose.data.right
          this.loudness_arr[2].state=respose.data.exright
          this.loudness_arr[3].num=respose.data.leftloop
          this.loudness_arr[3].state=respose.data.exleftloop
          this.loudness_arr[4].num=respose.data.rightloop
          this.loudness_arr[4].state=respose.data.exrightloop
          this.loudness_arr[5].num=respose.data.lf
          this.loudness_arr[5].state=respose.data.exlf
          this.projector=respose.data.projector
          this.server=respose.data.server
          this.equipment=respose.data.equipment
          this.decoder=respose.data.decoder
          this.other = respose.data.note
        }
        loadingInstance.close();
      }).catch(error=>{
        console.log(error)
      })
      return
    }

    //影院查看
    getOneHallResult({
      hid:this.$route.params.id,
      token:localStorage.getItem("token")
    }).then(respose=>{
      if(respose.state==="1"){
        this.main_flag=true;
        /*初始化数据*/
        this.track_flag=respose.data.channel==="支持"
        this.ac_sound_flag=respose.data.hum==="无"
        this.brightness_2d_text=respose.data.bright2D
        this.brightness_3d_flag=respose.data.bright3D!=="无"?this.brightness_3d_text=respose.data.bright3D:false
        this.definition_flag=respose.data.clarity==="正常"
        this.convergence_flag=respose.data.converge==="正常"
        this.loudness_arr[0].num=respose.data.middle
        this.loudness_arr[0].state=respose.data.exmiddle
        this.loudness_arr[1].num=respose.data.left
        this.loudness_arr[1].state=respose.data.exleft
        this.loudness_arr[2].num=respose.data.right
        this.loudness_arr[2].state=respose.data.exright
        this.loudness_arr[3].num=respose.data.leftloop
        this.loudness_arr[3].state=respose.data.exleftloop
        this.loudness_arr[4].num=respose.data.rightloop
        this.loudness_arr[4].state=respose.data.exrightloop
        this.loudness_arr[5].num=respose.data.lf
        this.loudness_arr[5].state=respose.data.exlf
        this.projector=respose.data.projector
        this.server=respose.data.server
        this.equipment=respose.data.equipment
        this.decoder=respose.data.decoder
        this.seats=respose.data.seats
        this.other = respose.data.note
      }else{
        // 没有数据 显示空状态
        this.empty_flag=true;
      }
      loadingInstance.close();
    }).catch(error=>{
      console.log(error)
    })
  },
  components:{
    NavBar,
    [Empty.name]:Empty
  },
  data(){
    return{
      title:'1号影院厅',
      track_flag:true, // 声道 true为支持,false缺失
      loudness_arr:[ // 响度数组
        {name:'中声道',state:'正常',num:''},
        {name:'左声道',state:'正常',num:''},
        {name:'右声道',state:'正常',num:''},
        {name:'左环绕',state:'正常',num:''},
        {name:'右环绕',state:'正常',num:''},
        {name:'低频',state:'正常',num:''},
      ],
      ac_sound_flag:true, // 放映过程中交流声 true为有,false无
      brightness_2d_text:'', // 银幕中心亮度2D 11-17F1
      brightness_3d_flag:true, // 银幕中心亮度3D大于 3.975F1 ture在文本框输入 false无
      brightness_3d_text:'', // 银幕中心亮度3D大于 3.975F1
      definition_flag:true, // 清晰度 true为正常,false模糊
      convergence_flag:true, //汇聚 true为正常,false偏差
      seats:'', // 座位数
      projector:'巴可（BARCO）', // 放映机
      server:'巴可（BARCO）ICMP', // 服务器
      server_flag:false,
      equipment:'飞达', // 3D设备
      equipment_flag:false,
      decoder:'单光路', // 解码器
      decoder_flag:false,
      main_flag:false, //是否显示数据内容
      empty_flag:false, //是否显示空状态  两个都用false是防止屏幕闪烁
      other:'', //备注
    }
  },
  computed:{
    loudness_err(){
      return v=>{
        if(v.state!='正常'){
          return false
        }
        if(v.num){
          switch (v.name){
            case '中声道':
              return true
              break;
            case '左声道':
            case '右声道':
              return this.loudness_arr[0].num-6<=v.num-0 && v.num-0<=this.loudness_arr[0].num-0+6
              break;
            case '右环绕':
            case '左环绕':
              return this.loudness_arr[0].num-9<=v.num-0 && v.num-0<=this.loudness_arr[0].num-0+3
              break;
            case '低频':
              return this.loudness_arr[0].num-0<=v.num-0 && v.num-0<=this.loudness_arr[0].num-0+12
              break;
            default:
              break
          }
        }else{
          return true
        }
      }
    },
    brightness_2d_err(){
      if(this.brightness_2d_text){
        return this.brightness_2d_text<=11
      }
    },
    brightness_3d_err(){
      if(!this.brightness_3d_flag){
        return false
      }
      if(this.brightness_3d_text){
        return this.brightness_3d_text<3.5
      }
    },
  },
}
</script>

<style scoped>
/*.nav_bottom{*/
/*  margin-bottom: 51px*/
/*}*/
.cinema_testing_result {
  padding-top: 51px;
  padding-bottom: 31px;
}
/*button被选中的样式*/
.success {
  background: #5F7BFF;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
/*button未被选中的样式*/
.error {
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #656565;
  background: #EFEFEF;
}
/*所有button的样式*/
.all_btn {
  width: 135px;
  height: 54px;
  border-radius: 7px;
  border: 0;
  text-align: center;
  line-height: 54px;
}
/*所有input的样式*/
.all_input {
  width: 131px;
  height: 50px;
  border-radius: 7px;
  border: 2px solid #5F7BFF;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5F7BFF;
  text-align: center;
  line-height: 50px;
}
/*设备信息右边样式*/
.all_device_input {
  width: 294px;
  height: 54px;
  background: #EFEFEF;
  border-radius: 7px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 54px;
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;/*IE 10+*/
  overflow: -moz-scrollbars-none;/*Firefox*/
}
/*flex布局 垂直居中+两边对齐*/
.flex_space_between_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main {
  width: 636px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 0 30px 28px;
  padding: 37px 34px 0px 22px;
}
main .track {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 626px;
  padding-bottom: 48px;
  border-bottom: 2px solid #F2F2F2;
  margin-left: 15px;
  position: relative;
}
main .track .tip {
  width: 283px;
  height: 110px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}
main .track .tip p {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  margin: 0;
}
main .track .tip span {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
  line-height: 35px;
}
main .track .all_btn {
  margin-right: 5px;
}
main .loudness {
  width: 626px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 48px;
  padding-bottom: 33px;
}
main .loudness .title {
  height: 42px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}
main .loudness .title p {
  width: 118px;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
}
main .loudness .title span {
  width: 503px;
  height: 31px;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
  word-break: break-all;
  word-wrap: break-word;
}
main .loudness .main {
  padding-top: 77px;
}
main .loudness .main .item {
  margin: 0 auto 24px;
  width: 550px;
  height: 54px;
  display: flex;
  align-items: center;
  position: relative;
}
main .loudness .main .item p {
  width: calc(94px + 147px);
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 0 ;
}
main .loudness .main .item .state {
  width: 133px;
  height: 54px;
  background: #EFEFEF;
  border-radius: 7px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #707070;
  line-height: 54px;
  text-align: center;
  margin-right: 40px;
}
main .ac_sound {
  width: 626px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 61px;
  padding-bottom: 45px;
  border-bottom: 2px solid #F2F2F2;
  position: relative;
}
main .ac_sound .tip {
  margin: 0 70px 0 5px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
}
main .brightness_2d {
  width: 626px;
  padding-top: 53px;
  padding-bottom: 50px;
  border-bottom: 2px solid #F2F2F2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
main .brightness_2d .tip {
  margin: 0 89px 0 5px;
  height: 84px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 40px;
  text-align: center;
}
main .brightness_3d {
  width: 626px;
  padding-top: 53px;
  padding-bottom: 50px;
  border-bottom: 2px solid #F2F2F2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
main .brightness_3d .tip {
  margin: 0 80px 0 0px;
  width: 240px;
  height: 84px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 40px;
  text-align: center;
}
main .definition {
  width: 626px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 56px 0;
  border-bottom: 2px solid #F2F2F2;
  position: relative;
}
main .definition .tip {
  width: 310px;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 35px;
}
main .convergence {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 626px;
  border-bottom: 2px solid #F2F2F2;
  margin: 0 0 56px;
  padding: 56px 0 78px;
  height: 76px;
  position: relative;
}
main .convergence .tip {
  width: 470px;
  height: 110px;
  display: flex;
  flex-direction: column;
}
main .convergence .tip p {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
  margin: 0;
}
main .convergence .tip span {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
  line-height: 35px;
}
main .convergence .all_btn {
  margin-right: 5px;
}
main .convergence .tip {
  justify-content: center;
}
main .convergence .all_btn {
  margin: 0;
}
main .seats_num {
  width: 626px;
  padding-top: 56px;
  padding-bottom: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main .seats_num .tip {
  width: 500px;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
}
main .device_info{
  width: 644px;
  border-bottom: 2px solid #F2F2F2;
}
main .beizhu{
  padding-top: 56px;
  width: 644px;
  padding-bottom: 40px;
}
 main .device_info .tip{
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #000000;
}
main .device_info p,main .beizhu p{
  width: 600px;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.69);
  margin: 0 0 108px;
}
main .beizhu p{
  margin: 0 0 30px;
}
.beizhu_content{
  width: 100%;
  min-height: 100px;
  font-size: 32px;
  white-space: pre-wrap;
}
main .device_info .device_projector {
  width: 626px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
}
main .device_info .device_server {
  width: 626px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
}
main .device_info .device_3d {
  width: 626px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
  padding-bottom: 49px;
  margin-top: 63px;
}
main .device_info .device_decoder {
  width: 626px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 72px;
  border-bottom: 2px solid #F2F2F2;
  margin-top: 56px;
  border-bottom: none;
  padding-bottom: 76px;
  margin-top: 66px;
}
.status{
  font-weight: bold;
  color: red;
  font-size: 30px;
  position: absolute;
  right: -20px;
  /*如果需要兼容ie 那么这里就需要打开 目前是靠flex居中*/
  /*top: 50%;
  transform: translate(0,-50%);*/
}
</style>
